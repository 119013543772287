<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="modal--covid"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="covid--container">
        <div class="title--text">Protokol Covid</div>
        <div class="desc" v-html="covidProtocol"></div>
      </div>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      covidProtocol: '',
      modalName: 'modal-covid-protocol',
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.covidProtocol = event.params.covidProtocol;
    },
  },
};
</script>
